
import { FormController, validationRules } from '@icepanel/app-form'
import { OrganizationPlan } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as firebase from '@/plugins/firebase'

import * as analytics from '../helpers/analytics'
import * as authListener from '../helpers/auth-listener'
import { UserModule } from '../store'

@Component({
  name: 'UserLogin'
})
export default class extends Vue {
  userModule = getModule(UserModule, this.$store)

  showPassword = false
  formInteracted = false

  get plan () {
    return this.$queryValue('plan') as OrganizationPlan | null
  }

  get registeredEmail () {
    return this.$queryValue('registered_email')
  }

  get registered () {
    return this.$queryValue('registered')
  }

  get imageSrc () {
    switch (this.plan) {
      case 'indie': return require('../assets/brian/email-indie.png')
      case 'growth': return require('../assets/brian/email-growth.png')
      default: return require('../assets/brian/email-free.png')
    }
  }

  formController = new FormController({
    initialModel: {
      email: typeof this.$route.query.email === 'string' ? this.$route.query.email : '',
      password: ''
    },
    validationRules: {
      email: [
        ...validationRules.exists,
        ...validationRules.email
      ],
      password: [
        ...validationRules.exists,
        ...validationRules.password
      ]
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      const email = model.email.toLowerCase()

      const { authToken } = await this.userModule.userAuthPassword({
        email,
        password: model.password
      })

      authListener.initialize()

      await this.userModule.userLogin({
        app: firebase.app,
        token: authToken
      })

      analytics.userLogin.track(this, {
        userAuthProvider: 'password'
      })

      await this.$router.push({
        name: 'organizations'
      })
    }
  }

  mounted () {
    analytics.userLoginScreen.track(this, {
      userAuthProvider: 'password'
    })
  }

  formInteraction () {
    if (!this.formInteracted) {
      this.formInteracted = true

      analytics.userLoginForm.track(this, {
        userAuthProvider: 'password'
      })
      analytics.userLogin.time()
    }
  }
}
